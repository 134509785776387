import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { CSSTransition } from 'react-transition-group';
import { useDispatch, useSelector } from 'react-redux';
import { setBoth } from '../../redux/reducers/PageReducer';
import pauseButton from '../../assets/images/Pause_button_white.png';
import playButton from '../../assets/images/Play_button_white.png';
import rewindButton from '../../assets/images/10_Sec_rewind_white.png';
import Duration from '../TrainingVideo/Duration';
import VideoEndOverlay from '../TrainingVideo/VideoEndOverlay';
import VideoWelcomeOverlay from '../TrainingVideo/VideoWelcomeOverlay';
import VideoPauseOverlay from '../TrainingVideo/VideoPauseOverlay';
import { useEffect } from 'react';
/*
    !!! the progress bar and counter in the display will not work with strict mode on (dev only) !!!
    - to adjust this, head over to index.js
*/

function VideoDisplay() {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.active_language);
    const location = useSelector((state) => state.location);
    const [video_url, setVideoUrl] = useState('https://videotervuren.s3.us-east-2.amazonaws.com/Tervuren-Demo-Video.mp4');
    
    // decide which video to use based on if the location has a video or not
    useEffect(() => {
        if (location.has_video) {
          setVideoUrl(location.video);
        } else {
          // Set default video URL if location has no video
          setVideoUrl('https://videotervuren.s3.us-east-2.amazonaws.com/Tervuren-Demo-Video.mp4');
        }
      }, [location]);


  // Initialize state for showing welcome, pause and end overlay
  const [activeOverlay, setActiveOverlay] = useState('welcome'); // 'welcome', 'pause', or 'end'


  // video control data
  const [isPlaying, setIsPlaying] = useState(false);
  const [played, setPlayed] = useState(0);
  const [isMuted] = useState(false);
  const video_player = useRef(null);
  const [duration, setDuration] = useState(0);

  const returnToLobby = () => {
    setActiveOverlay(null);
    dispatch(setBoth({ display: 'training', screen: 'training-lobby' }));
 };

  // start the video and hide welcome overlay
  const startVideo = () => {
    setActiveOverlay(null);
    setIsPlaying(true);
  };

  // handle restart video. need to hide panel as well
  const restartVideo = () => {
    setActiveOverlay(null);
    video_player.current.seekTo(0);
    setIsPlaying(true);
  };

  // handle pause button
  const handlePause = (e) => {
    setIsPlaying(false);
    setActiveOverlay('pause');
  };

  // handle play button
  const handlePlay = () => {
    setIsPlaying(true);
    setActiveOverlay(null);
  };

  // rewind video ten seconds on click
  const handleRewind = () => {
    var new_time = video_player.current.getCurrentTime() - 10;
    video_player.current.seekTo(new_time);
  };

  // used to show the video progress in progress bar
  const handleProgress = (e) => {
    setPlayed(e.played);
  };

  // used to show time played so far
  const handleDuration = (duration) => {
    setDuration(duration);
  };

  // Handle video end
  const handleVideoEnd = () => {
    setActiveOverlay('end'); 
  };

  // go to the next section
  const goToReview = () => {
    setActiveOverlay(null);
    dispatch(setBoth({ display: 'review', screen: 'start' }));
  };

  return (
    <>
      <div className="video-page-wrapper">

        {/* Render the welcome overlay conditionally */}
        {activeOverlay === 'welcome' && (
          <VideoWelcomeOverlay
            language={language}
            returnToLobby={returnToLobby}
            startVideo={startVideo}
          />
        )}

        {/* Render the pause overlay conditionally */}
        {activeOverlay === 'pause' && (
          <VideoPauseOverlay
            language={language}
            returnToLobby={returnToLobby}
            resumePlay={handlePlay}
            restartVideo={restartVideo}
          />
        )}

        <ReactPlayer
          className="react-player fixed-bottom"
          ref={video_player}
          url={video_url}
          width="100vw"
          height="100vh"
          controls={false}
          muted={isMuted}
          playing={isPlaying && activeOverlay === null} // Control playback based on welcome overlay visibility
          onEnded={handleVideoEnd}
          onProgress={handleProgress}
          onDuration={handleDuration}
          onClick={() => {
            if (isPlaying) {
                handlePause();  // Pause the video and show the pause overlay
            } else {
                handlePlay();  // Resume the video and hide the pause overlay
            }
        }}
        />

        <div className="video-control-panel">
          {/* show play or pause button based off of if the video is playing or not */}
          {isPlaying ? (
            <button onClick={handlePause} className={`player-buttons`}>
              <img src={pauseButton} alt="Next Arrow" />
            </button>
          ) : (
            <button onClick={handlePlay} className={`player-buttons`}>
              <img src={playButton} alt="Next Arrow" />
            </button>
          )}
         

          <button
            onClick={handleRewind}
            className={`player-buttons margin-left-spacer`}
          >
            <img src={rewindButton} alt="Next Arrow" />
          </button>

          <progress max={1} value={played} />

          <div className={'time-elapsed-wrapper'}>
            <Duration seconds={duration * played} className={'time-elapsed'} />
          </div>
        </div>

        {/* show slide out panel based on if the video is over or not */}
        {activeOverlay === 'end' && (
            <CSSTransition
              in={activeOverlay === 'end'}
              timeout={500}
              classNames={{ enter: 'panel-enter', exit: 'panel-exit' }}
            >
              <VideoEndOverlay
                language={language}
                restartVideo={restartVideo}
                goToReview={goToReview}
              />
            </CSSTransition>
        )}
      </div>
    </>
  );
}

// animation resources
// https://blog.shahednasser.com/how-to-animate-components-entrance-and-exit-in-react/
// https://reactcommunity.org/react-transition-group/css-transition

// controls bar:
// https://github.com/CookPete/react-player/blob/HEAD/src/demo/App.js
// https://github.com/cookpete/react-player/tree/d86f0e09ad19416d561a6c1c1702f97f91c450d6
// https://cookpete.com/react-player/

export default VideoDisplay;
