import * as ActionTypes from '../ActionTypes';
import {
  LoginUserService,
  ForgotPasswordService,
  ResetPasswordService,
} from '../../services/AuthServices';
import { setAllUserInfo } from '../reducers/UserReducer';
import { setAllLocationInfo } from '../reducers/LocationReducer';
import { setAllCompanyInfo } from '../reducers/CompanyReducer';
import { GetCompanyService } from '../../services/CompanyServices';
import { GetLocationService } from '../../services/LocationsServices';
import { setScreen } from '../../redux/reducers/PageReducer';
import { persistor, store } from '../../redux/Store';
import { resetCompanyState } from '../reducers/CompanyReducer';
import { resetLanguageState } from '../reducers/LanguageReducer';
import { resetLocationState } from '../reducers/LocationReducer';
import { resetPageState } from '../reducers/PageReducer';
import { resetTraineesState } from '../reducers/TraineesReducer';
import { resetUserState } from '../reducers/UserReducer';
import { getAllLanguagesAction } from './LanguageActions';

export const LoginAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    // clear any pervioous states just in case
    dispatch(resetCompanyState());
    dispatch(resetLanguageState());
    dispatch(resetLocationState());
    dispatch(resetPageState());
    dispatch(resetTraineesState());
    dispatch(resetUserState());

    LoginUserService(credentials).then(
      (res) => {
        console.log('login res:', res);
        if (res.hasOwnProperty('success') && res.success === true) {
          // set user token
          localStorage.setItem('user-token', res.tokens);
          //TODO: Below is breaking the login process, need to fix
          //   localStorage.setItem('user-token', res.tokens.AccessToken);
          //   localStorage.setItem(
          //     'expires-in-unix-time',
          //     res.tokens.ExpiresInUnixTime
          //   );

          // set login success
          dispatch({ type: ActionTypes.LOGIN_SUCCESS });

          // set user info
          dispatch(setAllUserInfo(res.user));

          // if res.user.location_id contains a comma then navigate them to the location selection screen
          if (res.user.location_id.includes(',')) {
            dispatch(setScreen('location-selection'));
            setTimeout(() => {
              history('/location-selection');
            }, 1);
            return;
          }
          // get location info
          GetLocationService(`id/${res.user.location_id}`).then(
            (res) => {
              if (res) {
                //console.log('location res', res);
                dispatch(setAllLocationInfo(res));
              } else {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
              }
            },
            (error) => {
              dispatch({ type: ActionTypes.CODE_ERROR, error });
            }
          );

          // get company info
          GetCompanyService(`id/${res.user.company_id}`).then(
            (res) => {
              if (res) {
                //console.log('company res', res);
                dispatch(setAllCompanyInfo(res));
              } else {
                dispatch({ type: ActionTypes.LOGIN_ERROR, res });
              }
            },
            (error) => {
              dispatch({ type: ActionTypes.CODE_ERROR, error });
            }
          );

          // get languages
          dispatch(getAllLanguagesAction());

          // move authenticated to main training screen
          dispatch(setScreen('training-lobby'));
          setTimeout(() => {
            history('/');
          }, 1);
        } else {
          dispatch({ type: ActionTypes.LOGIN_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
}; //[end] LoginAction()

// Logout action
export const LogoutAction = () => {
  return (dispatch) => {
    // Redirect to login screen
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE }); // Reset authentication state

    // Redirect to login screen

    // Dispatch logout action
    store.dispatch({ type: 'LOGOUT' });
    // clear the states
    dispatch(resetCompanyState());
    dispatch(resetLanguageState());
    dispatch(resetLocationState());
    dispatch(resetPageState());
    dispatch(resetTraineesState());
    dispatch(resetUserState());

    // Purge the persisted state
    persistor.purge();

    // Clear user token from localStorage
    localStorage.removeItem('user-token');
    localStorage.removeItem('expires-in-unix-time');
  };
};

// check res.tokens.ExpiresInUnixTime to see if token is expired if so, logout user
export const CheckTokenAction = (history) => {
  return (dispatch) => {
    const expires = parseInt(localStorage.getItem('expires-in-unix-time'), 10); // Make sure it's an integer
    const currentTimeInSeconds = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
    console.log('expires in:', expires);
    console.log('current time:', currentTimeInSeconds);

    if (expires < currentTimeInSeconds) {
      dispatch(LogoutAction());
    }
  };
};

// export const ClearAuthStateAction = () => {
//   return (dispatch) => {
//     dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
//   };
// };

// Forgot password action
export const ForgotPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ForgotPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_SUCCESS, res });
          //setTimeout(() => { history('/'); },1500);
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.FORGOT_PASSWORD_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

// Reset password action
export const ResetPasswordAction = (credentials, history) => {
  return (dispatch) => {
    dispatch({ type: ActionTypes.RESTART_AUTH_RESPONSE });
    dispatch({ type: ActionTypes.LOADING });

    ResetPasswordService(credentials).then(
      (res) => {
        if (res.hasOwnProperty('success') && res.success === true) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_SUCCESS, res });
        } else if (res.hasOwnProperty('success') && res.success === false) {
          dispatch({ type: ActionTypes.PASSWORD_RESET_ERROR, res });
        }
      },
      (error) => {
        dispatch({ type: ActionTypes.CODE_ERROR, error });
      }
    );
  };
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
