import { useSelector, useDispatch } from 'react-redux';
import { setScreen } from '../../redux/reducers/PageReducer';
import {
  removeActiveTrainee,
  setActiveTrainee,
} from '../../redux/reducers/TraineesReducer';
import { setActiveLanguage } from '../../redux/reducers/LanguageReducer';

function WhichTraineeAreYou({ children, className }) {
  const language = useSelector((state) => state.language.active_language);
  const dispatch = useDispatch();
  const trainee_info = useSelector((state) => state.trainees.active_trainee);
  const trainees_list = useSelector(
    (state) => state.trainees.possible_trainee_list
  );

  // if user states no found trainees are them, send to blank new here form
  const newTraineeToForm = () => {
    //remove active trainee
    dispatch(removeActiveTrainee(trainee_info));
    //proceede to trainee info
    dispatch(setScreen('trainee-information'));
  };

  // the user agrees the user found is them. Send to editable edit details screen
  const submitButton = (id) => {
    // get trainee
    let chosen_trainee = trainees_list.find((trainee) => trainee.id === id);
    // set active trainees
    dispatch(setActiveTrainee(chosen_trainee));
    if(chosen_trainee.preferred_language !== '') {
        dispatch(setActiveLanguage(chosen_trainee.preferred_language));
    }
    //proceed to trainee info
    dispatch(setScreen('trainee-information'));
  };

  const listItems = trainees_list.map((trainee) => (
    <li key={trainee.id}>
      <button
        type="submit"
        className="which-are-you-button"
        data-id={trainee.id}
        onClick={() => submitButton(trainee.id)}
      >
        <div className="is-this-you-personal-info">
          {trainee.first_name} {trainee.last_name}
        </div>
        <div className="is-this-you-personal-info">{trainee.email}</div>
        <div className="is-this-you-personal-info">{trainee.company}</div>
      </button>
    </li>
  ));

  return (
    <>
      <div className="whos-training-wrapper">
        <h2 className="training-heading">{language.any_of_these_you_text}</h2>
        <p className="training-text">{language.any_of_these_you_subtext}</p>
      </div>

      <div id="which-are-you-buttons-wrapper">
        <ul>{listItems}</ul>
      </div>

      <div className="form-bottom-button-wrapper text-left">
        <button className="button button-light" onClick={newTraineeToForm}>
          {language.none_are_me_button}
        </button>
      </div>
    </>
  );
}

export default WhichTraineeAreYou;
