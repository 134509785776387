import WhosTraining from './WhosTraining';
import TraineeList from '../TraineeList';
import { useDispatch, useSelector} from 'react-redux';
import { clearPossibleTrainees } from '../../redux/reducers/TraineesReducer';
import { useEffect, useState } from 'react';
import { parseJwt } from '../../redux/actions/AuthActions';
import { useNavigate } from 'react-router-dom';
import { LogoutAction } from '../../redux/actions/AuthActions';
import { setActiveLanguage } from '../../redux/reducers/LanguageReducer';
import SessionEndedModal from '../ModalSessionEnded';


function TrainingLobby (){
    const dispatch = useDispatch();
    const nav = useNavigate();
    const number_of_trainees = useSelector((state) => state.trainees.number_of_trainees);
    const default_language_code = useSelector((state) => state.location.preferred_language);
    const [isSessionModalOpen, setIsSessionModalOpen] = useState(false);

    // run on component mount
    useEffect(() => {
        // clear possible trainees list | trigger on component mount
        dispatch(clearPossibleTrainees());

        // set the language to the location's default language
        dispatch(setActiveLanguage(default_language_code));

        // log out user if the token has expired
        const decodedJwt = parseJwt(localStorage.getItem('user-token'));
        if (decodedJwt) {
            // check if the token has expired. If so, log out the user
            if (decodedJwt.exp * 1000 < Date.now()) {
                // show session ended popup
                setIsSessionModalOpen(true);
            }
        }
 
        // next comment line -> old es lint versions flag this but it is okay. this is just hiding a warning
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // handle logginf out after user session has expired modal is closed
    const handleSessionModalClose = () => {
        setIsSessionModalOpen(false);
        dispatch(LogoutAction());
        nav('/login');
    };



    
    return(
        <>
            <div className="">
                <WhosTraining/>
                {number_of_trainees > 0 ? <TraineeList/> : null}
            </div>
            <SessionEndedModal
                isOpen={isSessionModalOpen}
                onClose={handleSessionModalClose}
            />
        </>
    )
}

export default TrainingLobby
