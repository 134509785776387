import { removeTrainee } from '../redux/reducers/TraineesReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setActiveTraineeByID } from '../redux/reducers/TraineesReducer';
import Modal from './Modal';
import { useEffect, useState } from 'react';
import { setActiveLanguage } from '../redux/reducers/LanguageReducer';

function TraineeListItem(props) {
    const dispatch = useDispatch();
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const trainee_list = useSelector((state) => state.trainees.trainee_list);
    const current_display = useSelector((state) => state.page.display);
    const active_trainee = useSelector((state) => state.trainees.active_trainee);
    const active_trainee_id = active_trainee?.id || null;

    const RemoveTraineeConfirmation = ({ onClose, onConfirm, name }) => (
        <div className="form-bottom-button-wrapper">
            <p>Are you sure you want to remove {name}?</p>
            <div className="button-wrapper">
                <button className="button button-blue" onClick={onConfirm}>
                Yes, Remove
                </button>

                <button className="button button-blue" onClick={onClose}>
                Cancel
                </button>
            </div>
        </div>
    );

    const { id, first_name, last_name } = props.data || {
        id: '', // Provide a sensible default or ensure this case is handled
        first_name: 'Default',
        last_name: 'Trainee',
    };


    // remove trainee from trainee's list if they click on the little red x
    const removeTraineeClick = () => {
        setIsRemoveModalOpen(true); // Show the modal for confirmation
    };

    // set clicked trainee as active trainee. Only if it's the trainee list in the review stage
    const selectList = (id) => {
        if (current_display === 'review') {
            dispatch(setActiveTraineeByID(id));
        }
    };

    // set the active language to the trainee's preferred language
    useEffect(() => {
        if (current_display === 'review' && active_trainee) {
            dispatch(setActiveLanguage(active_trainee.preferred_language));
        }
    }, [active_trainee, current_display, dispatch]);

    return (
        <>
        <div
            className={`trainee-list-item ${id === active_trainee_id ? 'is-active' : ''}`}
            data-id={id}
            onClick={() => selectList(id)}
        >
            <p>{' '}{first_name} {last_name}</p>
            <div
            className="trainee-status-icon"
            data-id={id}
            onClick={removeTraineeClick}
            ></div>
        </div>
        <Modal
            isOpen={isRemoveModalOpen}
            onClose={() => setIsRemoveModalOpen(false)}
        >
            <RemoveTraineeConfirmation
            onConfirm={() => {
                dispatch(removeTrainee(id));
                if (active_trainee_id === id) {
                    let next_trainee = trainee_list.filter(
                        (obj) => obj.id !== id
                    );
                    dispatch(setActiveTraineeByID(next_trainee[0]?.id || null));
                }
                setIsRemoveModalOpen(false); // Close modal on confirmation
            }}
            onClose={() => setIsRemoveModalOpen(false)}
            name={`${first_name} ${last_name}`}
            />
        </Modal>
        </>
    );
}
export default TraineeListItem;
