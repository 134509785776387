import React, { useState, useEffect } from 'react';

const LoadingButton = ({
  initialLabel,
  onSubmit,
  type = 'button',
  loadingLabel = 'Loading...', // Optional prop
  extraClasses,
}) => {
  const [label, setLabel] = useState(initialLabel);
  const [loading, setLoading] = useState(false);

  // This effect ensures that the label updates are reflected in the UI
  // Use effect to update the label when the initialLabel prop changes
  useEffect(() => {
    if (!loading) {
      setLabel(initialLabel);
    }
  }, [initialLabel, loading]); // Run effect when initialLabel or loading state changes


  const handleClick = async (e) => {
    e.preventDefault(); // Prevent form submission if handled here
    setLoading(true);
    setLabel(loadingLabel);
    try {
      await onSubmit();
    } catch (error) {
      console.error('Error during login:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      className={`button button-blue ${extraClasses} `}
      disabled={loading}
      onClick={handleClick}
      type={type}
    >
      {label}
    </button>
  );
};

export default LoadingButton;
