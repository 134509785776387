import moment from 'moment';


// Utility function to determine if the training is expired and return the expiration date
export const getTrainingExpiration = (trainee, location_expiration_type, customExpirationDate) => {
    const last_training = trainee?.last_training ? parseInt(trainee.last_training) * 1000 : null;

    if (!last_training) return { expired: true, expirationDate: null }; // If no last training, return default expired

    const trainingDate = moment(last_training);  // Convert last_training to a moment object
    let expirationDate;

    // Handle different expiration types
    switch (location_expiration_type) {
        case 'year':
            // Add 1 year to the training date
            expirationDate = trainingDate.add(1, 'year');
            break;

        case 'calendar-year':
            // Set expiration to the end of the year when the training took place
            expirationDate = trainingDate.endOf('year');
            break;

        case 'custom-date':
            // Custom expiration logic for a specific date of the year
            const currentYear = moment().year();
            const customDateThisYear = moment(`${currentYear}-${customExpirationDate}`, 'YYYY-MM-DD');
            const customDateNextYear = customDateThisYear.clone().add(1, 'year');

            if (trainingDate.isBefore(customDateThisYear)) {
                // Training happened before custom date this year, expiration is this year's custom date
                expirationDate = customDateThisYear;
            } else {
                // Training happened after custom date, expiration is next year's custom date
                expirationDate = customDateNextYear;
            }
            break;

        default:
            // Fallback to 1 year if no expiration type is matched
            expirationDate = trainingDate.add(1, 'year');
            break;
    }

    // Check if the training has expired
    const expired = expirationDate.isBefore(moment());

    return { expired, expirationDate };
};
