import React from 'react';

function SkipVideoModal({ isOpen, playVideo, skipVideo }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="form-bottom-button-wrapper">
            <p>All Trainees Are Up To Date On Their Trainings. Do You Want To Skip The Video Portion?</p>
            <div className="button-wrapper">
                <button className="button button-blue" onClick={playVideo}>
                NO, Play Video
                </button>
                <button className="button button-blue" onClick={skipVideo}>
                YES, Skip Video
                </button>
            </div>
        </div>
        
      </div>
    </div>
  );
}

export default SkipVideoModal;