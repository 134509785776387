import stackedLogo from '../../assets/images/tervuren-logo-white-vert-lg.png';

export default function VideoPauseOverlay(props) {
  return (
    <div className="video-panel-overlay">
      <div className="inner-wrapper">
        <img className="terv-logo" src={stackedLogo} alt="Stacked Logo" />

        <div className="button-wrapper">
          <button className="button button-clear" onClick={props.returnToLobby}>
            {'Return Home'}
          </button>
          <button className="button button-clear" onClick={props.restartVideo}>
            {props.language.restart_video || 'Restart Video'}
          </button>
          <button className="button button-blue" onClick={props.resumePlay}>
            {'Resume Video'}
          </button>
        </div>
      </div>
    </div>
  );
}
