/*
    this file handles all of the actions related to trainees that interact with the backend api
*/
import {
  PutTraineesService,
  PutTraineeService,
  GetTraineeService,
  PostTraineeService,
} from '../../services/TraineeServices';
import {
  setTraineeConnectionResponse,
  addCompletedTrainee,
  addTrainee,
  setPossibleTrainees,
  setActiveTrainee,
  removeActiveTrainee,
  removeTrainee,
} from '../reducers/TraineesReducer';
import { setScreen } from '../reducers/PageReducer';


export const UpdateSingleTraineeAction = (traineeData) => {
  let url = '';
  if (traineeData.id !== null && traineeData.id !== undefined) {
    url = `/update/${traineeData.id}` || ``;
  }
  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));

    if (traineeData.id !== null && traineeData.id !== undefined) {
      PostTraineeService(traineeData, url).then(
        (res) => {
          if (res) {
            dispatch(addTrainee(res));
            // clear active trainee
            dispatch(removeActiveTrainee());
            // move back to training lobby
            dispatch(setScreen('training-lobby'));
          } else if (res.hasOwnProperty('success') && res.success === false) {
            dispatch(setTraineeConnectionResponse(res.message));
          }
        },
        (error) => {
          dispatch(setTraineeConnectionResponse(error));
        }
      );
    } else {
      PutTraineesService(traineeData, url).then(
        (res) => {
          if (res) {
            dispatch(addTrainee(res));
            // clear active trainee
            dispatch(removeActiveTrainee());
            // move back to training lobby
            dispatch(setScreen('training-lobby'));
          } else if (res.hasOwnProperty('success') && res.success === false) {
            dispatch(setTraineeConnectionResponse(res.message));
          }
        },
        (error) => {
          dispatch(setTraineeConnectionResponse(error));
        }
      );
    } // [end] return dispatch
  };
}; //[end] UpdateSingleTraineeAction ()







// /**
//  * 
//  * this is only called when the "whos training" form is filled out and submitted
//  */

export const GetPossibleTraineesAction = (traineeData, user) => {
  let url = `/phone/${traineeData.phone}/${
    traineeData.location_id || user.location_id
  }`;

  return (dispatch) => {
    //clear response state
    dispatch(setTraineeConnectionResponse(''));
    GetTraineeService(url).then(
      (res) => {
        if (res === null) {
          url = `/name/${traineeData.first_name}/${traineeData.last_name}/${traineeData.location_id}`;
          GetTraineeService(url).then((res) => {
            if (res !== null && res.length > 0) {
              dispatch(setTraineeConnectionResponse(res));
              // set possible trainees list
              dispatch(setPossibleTrainees(res));
              let trainees_list = [res];

              // now see how many possible trainees were found
              if (
                trainees_list !== '' &&
                trainees_list !== null &&
                trainees_list !== undefined
              ) {
                // if just one employee, do the simple way and add to active trainee state. then show is this you screen
                if (trainees_list !== '') {
                  dispatch(setActiveTrainee(trainees_list[0]));
                  // move to confirmation screen
                  dispatch(setScreen('is-this-you'));
                } else {
                  // many employees match, will have to show a list to choose from
                  // if more than one, show a list to choose which one you are. move to whcihi trainee are you screen
                  dispatch(setScreen('which-trainee-are-you'));
                }
              } else {
                // if zero, send to new trainee form
                // store temp info from form as active trainee
                // first, last, email, company
                let temp_trainee = {};
                if (traineeData.hasOwnProperty('first_name')) {
                  temp_trainee['first_name'] = traineeData.first_name;
                }
                if (traineeData.hasOwnProperty('last_name')) {
                  temp_trainee['last_name'] = traineeData.last_name;
                }
                if (traineeData.hasOwnProperty('phone')) {
                  temp_trainee['phone'] = traineeData.phone;
                }
                if (traineeData.hasOwnProperty('company')) {
                  temp_trainee['company'] = traineeData.company;
                }

                dispatch(setActiveTrainee(temp_trainee));
                //send to new trainee form
                dispatch(setScreen('trainee-information'));
              }
            } else {
              dispatch(setTraineeConnectionResponse(res));
              let temp_trainee = {};
              if (traineeData.hasOwnProperty('first_name')) {
                temp_trainee['first_name'] = traineeData.first_name;
              }
              if (traineeData.hasOwnProperty('last_name')) {
                temp_trainee['last_name'] = traineeData.last_name;
              }
              if (traineeData.hasOwnProperty('phone')) {
                temp_trainee['phone'] = traineeData.phone;
              }
              if (traineeData.hasOwnProperty('company')) {
                temp_trainee['company'] = traineeData.company;
              }

              dispatch(setActiveTrainee(temp_trainee));
              //send to new trainee form
              dispatch(setScreen('trainee-information'));
            }
          });
        } else if (res !== null) {
          dispatch(setTraineeConnectionResponse(res));
          // set possible trainees list
          dispatch(setPossibleTrainees(res));
          let trainees_list = [res];

          // now see how many possible trainees were found
          if (
            trainees_list !== '' &&
            trainees_list !== null &&
            trainees_list !== undefined
          ) {
            // if just one employee, do the simple way and add to active trainee state. then show is this you screen
            if (trainees_list.length === 1) {
              dispatch(setActiveTrainee(trainees_list[0]));
              // move to confirmation screen
              dispatch(setScreen('is-this-you'));
            } else {
              // many employees match, will have to show a list to choose from
              // if more than one, show a list to choose which one you are. move to whcihi trainee are you screen
              dispatch(setScreen('which-trainee-are-you'));
            }
          } else {
            // if zero, send to new trainee form
            // store temp info from form as active trainee
            // first, last, email, company
            let temp_trainee = {};
            if (traineeData.hasOwnProperty('first_name')) {
              temp_trainee['first_name'] = traineeData.first_name;
            }
            if (traineeData.hasOwnProperty('last_name')) {
              temp_trainee['last_name'] = traineeData.last_name;
            }
            if (traineeData.hasOwnProperty('phone')) {
              temp_trainee['phone'] = traineeData.phone;
            }
            if (traineeData.hasOwnProperty('company')) {
              temp_trainee['company'] = traineeData.company;
            }

            dispatch(setActiveTrainee(temp_trainee));
            //send to new trainee form
            dispatch(setScreen('trainee-information'));
          }
        } else {
          dispatch(setTraineeConnectionResponse(res));
          let temp_trainee = {};
          if (traineeData.hasOwnProperty('first_name')) {
            temp_trainee['first_name'] = traineeData.first_name;
          }
          if (traineeData.hasOwnProperty('last_name')) {
            temp_trainee['last_name'] = traineeData.last_name;
          }
          if (traineeData.hasOwnProperty('phone')) {
            temp_trainee['phone'] = traineeData.phone;
          }
          if (traineeData.hasOwnProperty('company')) {
            temp_trainee['company'] = traineeData.company;
          }

          dispatch(setActiveTrainee(temp_trainee));
          //send to new trainee form
          dispatch(setScreen('trainee-information'));
        }
      },
      (error) => {
        dispatch(setTraineeConnectionResponse(error));
      }
    );
  }; // [end] return dispatch
}; //[end] GetPossibleTraineesAction ()



/**
 * 
 *  This submits the trainee data to the backend api to complete the training
 */
export const TrainingCompletedAction = (active_trainee, completed_trainee) => {
    let url = '';

    return (dispatch) => {
        // Clear response state
        dispatch(setTraineeConnectionResponse(''));

        // If the traineeId is negative, it's a new trainee, so create one first
        if (active_trainee.id < 0) {
            // Create the new trainee
            PutTraineesService(active_trainee, url).then(
                (newTraineeRes) => {
                    if (newTraineeRes && newTraineeRes.id) {
                        // Use the new trainee's ID to complete the training
                        completed_trainee.id = newTraineeRes.id;
                        // make sure to use the new trainees fake id here
                        dispatch(completeTraineeTraining(completed_trainee, active_trainee.id));
                    } else {
                        dispatch(setTraineeConnectionResponse('Failed to create new trainee.'));
                    }
                },
                (error) => {
                    dispatch(setTraineeConnectionResponse(error.message || 'Error creating new trainee'));
                }
            );
        } else {
            url = `/update/${active_trainee.id}` || ``;
            PostTraineeService(active_trainee, url).then(
                (updateTraineeRes) => {
                    if (updateTraineeRes && updateTraineeRes.id) {
                        dispatch(completeTraineeTraining(completed_trainee, active_trainee.id));
    
                    } else{
                        dispatch(setTraineeConnectionResponse('Failed to update trainee.'));
                    }
                },
                (error) => {
                    dispatch(setTraineeConnectionResponse(error.message || 'Error updating trainee'));
                }
              );
        }
    };
};


// Helper action to complete trainee training
const completeTraineeTraining = (traineeData, traineeId) => {
    return (dispatch) => {
        let url = '/complete-training';

        PutTraineeService(traineeData, url).then(
            (res) => {
                if (res) {
                    // Add to finished list
                    dispatch(addCompletedTrainee(traineeData));
                    // Remove from trainees list
                    dispatch(removeTrainee(traineeId));
                    // Clear active trainee
                    dispatch(removeActiveTrainee());
                    // Show finished screen
                    dispatch(setScreen('finished'));
                } else if (res.hasOwnProperty('success') && res.success === false) {
                    dispatch(setTraineeConnectionResponse(res.message || 'Error completing training'));
                }
            },
            (error) => {
                dispatch(setTraineeConnectionResponse(error.message || 'Error completing training'));
            }
        );
    };
};