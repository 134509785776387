import React from 'react';
import Pagination from './Pagination';
import TraineeListItem from './TraineeListItem';
import { useSelector, useDispatch } from 'react-redux';
import { setScreen, setBoth } from '../redux/reducers/PageReducer';
import { setActiveTraineeByID} from '../redux/reducers/TraineesReducer';
import {setActiveLanguage} from '../redux/reducers/LanguageReducer';
import { SetCommonLanguageAction } from '../redux/actions/LanguageActions';
import { useEffect } from 'react';
import { getTrainingExpiration } from '../helpers/trainingExpired';
import SkipVideoModal from './Modals/SkipVideoModal';

export default function TraineeList() {
    const dispatch = useDispatch();
    const current_display = useSelector((state) => state.page.display);
    const trainees_list = useSelector((state) => state.trainees.trainee_list);
    const active_trainee = useSelector((state) => state.trainees.active_trainee);
    const location_expiration_type = useSelector((state) => state.location.training_expiration) || 'year'; // can be 'year', 'calendar-year', or 'custom-date'
    const customExpirationDate = useSelector((state) => state.location.custom_expiration_date) || '01/01'; 
    const [isOpen, setIsOpen] = React.useState(false);
    const [hasExpiredTraining, setHasExpiredTraining] = React.useState(false);

    const playVideo = () => {
        dispatch(setBoth({ display: 'video', screen: 'video' }));
    };

    const skipVideo = () => {
        dispatch(setBoth({ display: 'review', screen: 'start' }));
    };

    // if no trainees, go to welcome screen - catch all
    useEffect(() => {
        if (trainees_list.length < 1) {
            dispatch(setBoth({ display: 'training', screen: 'training-lobby' }));
        }
    }, [trainees_list, dispatch]);

    // if the current display is review, set the active trainee to the first trainee in the list
    useEffect(() => {
        if (current_display === 'review') {
            if (trainees_list[0] !== undefined){
                dispatch(setActiveTraineeByID(trainees_list[0].id));
            }
            
            // set the language based off of the selected trainees preffered language
        }
    }, [current_display, trainees_list, dispatch]);


    /**
     * There are two instances of this list in the app, to begin training and to go into the agreement area
     * each area has different logic for the button click
     */
    const startTraining = () => {
        // button clicked to go to watch the video
        if (current_display === 'training') {
            // find the most common language
            dispatch(SetCommonLanguageAction());

            // @TODO - this is where we would determine which video to show if there are multiple
            
            // go through the trainees and see if any have expired training, 
            // if not, show the popup asking if they want to skip the video
            trainees_list.forEach((trainee) => {
                const { expired } = getTrainingExpiration(
                    trainee,
                    location_expiration_type,
                    customExpirationDate
                );
                if (expired) {
                    // if any trainee has expired training, show the video
                    setHasExpiredTraining(true);
                    playVideo();
                    return;
                }
            });

            // if no trainee has expired training, show the popup. we wouldnt get here if any trainee's training was expired
            if (!hasExpiredTraining) {
                setIsOpen(true);
            }else{
                // if any trainee has expired training, show the video
                playVideo();
            }

        } else {
            // shown here at the complete your training section
            // set the language based off of the selected trainees preffered language
            if (active_trainee.preferred_language !== '') {
                dispatch(setActiveLanguage(active_trainee.preferred_language));
            }
          
            // move to agreement form
            dispatch(setScreen('agreement'));
        }
    };

    

  return (
        <div>
        {trainees_list[0] !== undefined ? (
            <>
            <div id="training-list-container">
                <Pagination
                    data={trainees_list}
                    RenderComponent={TraineeListItem}
                    pageLimit={0}
                    dataLimit={6}
                />
                <div className="trainee-button-wrapper">
                <button className="button button-blue" onClick={startTraining}>
                    {current_display === 'training'
                    ? 'Start Training'
                    : 'Agree & Continue'}
                </button>
                </div>
            </div>
            <SkipVideoModal 
                isOpen={isOpen}
                playVideo={playVideo}
                skipVideo={skipVideo} 
            />
            </>
        ) : (
            <div></div>
        )}
        </div>
  );
}
