import React from 'react';

function SessionEndedModal({ isOpen, onClose}) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="form-bottom-button-wrapper">
            <p>Youre session has expired, please login again</p>
            <div className="button-wrapper">
                <button className="button button-blue" onClick={onClose}>
                Go To Login
                </button>
            </div>
        </div>
        
      </div>
    </div>
  );
}

export default SessionEndedModal;
