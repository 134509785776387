import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { GetLocationService } from '../services/LocationsServices';
import { GetCompanyService } from '../services/CompanyServices';
import { setAllCompanyInfo } from '../redux/reducers/CompanyReducer';
import { setAllLocationInfo } from '../redux/reducers/LocationReducer';
import { setAllUserInfo } from '../redux/reducers/UserReducer';
import { getAllLanguagesAction } from '../redux/actions/LanguageActions';
import { setScreen } from '../redux/reducers/PageReducer';
import { useNavigate } from 'react-router-dom';

const LocationSelection = () => {
  const [locations, setLocations] = useState([]);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useNavigate();

  //const locationIds = user?.location_id?.split(',') || [];
  const locationIds = useMemo(() => {
    return user?.location_id?.split(',') || [];
  }, [user.location_id]);

  useEffect(() => {
    const fetchLocations = async () => {
      const fetchedLocations = [];
      for (let locationId of locationIds) {
        try {
          const res = await GetLocationService(`id/${locationId}`);
          if (res) {
            fetchedLocations.push(res); // Ensure res is an object with correct properties
          }
        } catch (error) {
          console.error('Error loading location:', error);
        }
      }
      setLocations(fetchedLocations);
    };

    if (locationIds.length) {
      fetchLocations();
    }
  }, [locationIds]);

  const onLocationSelect = (location) => async () => {
    try {
      dispatch(setAllLocationInfo(location));
      const companyRes = await GetCompanyService(`id/${location.company_id}`);
      if (companyRes) {
        // Create a shallow copy of the user object and update location_id
        const updatedUser = { ...user, location_id: location.id };

        dispatch(setAllCompanyInfo(companyRes));
        dispatch(setAllLocationInfo(location));
        dispatch(getAllLanguagesAction(location.language));
        dispatch(setScreen('training-lobby'));
        dispatch(setAllUserInfo(updatedUser)); // Use the new updated user object
        history('/training-lobby');
      }
    } catch (error) {
      console.error('Error loading company:', error);
    }
  };

  return (
    <div className="location-selection">
      <h2>Location Selection</h2>
      <ul>
        {locations.map((location) => (
          <li key={location.id}>
            <button className="button button-blue" onClick={onLocationSelect(location)}>
              {location.name} - {location.address}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LocationSelection;
