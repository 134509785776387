import { createSlice } from '@reduxjs/toolkit';

/**
 * Default state object with initial values.
 */
const initialState = {
    id: 0,
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    role: 0,
    location_id: 0,
    region_id: 0,
    company_id: 0,
};

/**
 * Create a slice as a reducer containing actions.
 */
export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAllUserInfo: (state, action) => {
        state.id = action.payload.id || 0;
        state.first_name = action.payload.first_name || '';
        state.last_name = action.payload.last_name || '';
        state.phone = action.payload.phone || '';
        state.email = action.payload.email || '';
        state.role = action.payload.role || 0;
        state.location_id = action.payload.location_id || 0;
        state.region_id = action.payload.region_id || 0;
        state.company_id = action.payload.company_id || 0;
    },
    resetUserState: () => initialState, // Reset to initial state
  },
});

export const { setAllUserInfo, resetUserState } = UserSlice.actions;
export default UserSlice.reducer;
