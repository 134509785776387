import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import general layout components
import LeftPanel from '../Layouts/LeftPanel';
import RightPanel from '../Layouts/RightPanel';
import CardCentered from '../CardCentered';
import ResetPasswordForm from '../Auth/ResetPasswordForm';

function ResetPassword() {
  const authResponseSuccess = useSelector(
    (state) => state.userAuth.authResponse.success
  );

  return (
    <>
      <div className="bg-topo-dark">
        <LeftPanel />
        <RightPanel className="">
          <CardCentered className="auth-card">
            {authResponseSuccess ? (
              <div className="row auth-response-text">
                <h3>
                  Password Successfully Changed.{' '}
                </h3>
                <div className="row">
                    <Link to="/login">Return to login</Link>
                </div>
              </div>
            ) : (
              <ResetPasswordForm />
            )}
          </CardCentered>
        </RightPanel>
      </div>
    </>
  ); //[end] return function
} // [end] ResetPassword()
export default ResetPassword;
