import stackedLogo from "../../assets/images/tervuren-logo-white-vert-lg.png"


export default function VideoWelcomeOverlay(props){
    return(
        <div className="video-panel-overlay">
            <div className="inner-wrapper">
                <img className="terv-logo" src={stackedLogo} alt="Stacked Logo"/>
                <div className="button-wrapper">
                    {/* TODO: sub this out for the right language */}
                    <button className="button button-clear" onClick={props.returnToLobby}>
                        {props.language.go_back_button || 'Go Back'}
                    </button>
                    <button className="button button-blue" onClick={props.startVideo}>
                        {props.language.start_video || 'Start Video'}
                    </button> 
                </div>
            </div>
        </div>
    )
}