import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TraineeList from '../TraineeList';
import { SetCommonLanguageAction } from '../../redux/actions/LanguageActions';

const TrainingStart = () => {
    const dispatch = useDispatch();
    const language = useSelector((state) => state.language.active_language);

    useEffect(() => {
        dispatch(SetCommonLanguageAction());
    }, [dispatch]);

  return (
    <div className="training-start-page">
      <div className="trainee-list-heading-wrapper">
        <h2 className="training-heading">{language?.trainee_list_text}</h2>
        <p className="training-text">{language?.trainee_list_subtext}</p>
      </div>
      <TraineeList />
    </div>
  );
};
export default TrainingStart;
