// import general layout components
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from '../../redux/actions/AuthActions';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LeftPanel from '../Layouts/LeftPanel';
import RightPanel from '../Layouts/RightPanel';
import CardCentered from '../CardCentered';

import LoadingButton from '../LoadingButton';

function LoginComponent() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(); // for form control
  const history = useNavigate();
  const dispatch = useDispatch();
  const authResponse = useSelector((state) => state.userAuth.authResponse);

  const onSubmit = async (data) => {
    const fields = { email: data.email, password: data.password };

    try {
        // Await the dispatch to ensure that the action resolves
        await dispatch(LoginAction(fields, history));
        // Optionally handle success here if needed
    } catch (error) {
        console.error('Login failed:', error);
        // Handle errors, e.g., update state to show an error message
    }

    // Simulating a network delay (2 seconds in this case)
    await new Promise((resolve) => setTimeout(resolve, 2000));
};


  return (
    <div className="bg-topo-dark">
      <LeftPanel />
      <RightPanel className="">
        <CardCentered className="auth-card">
          <h2>Site Login</h2>
          <form>
            <div className="row">
              <div className={` ${errors['email'] ? 'has-error' : ''}`}>
                <label htmlFor="email">Email</label>

                <input
                  type="email"
                  name="email"
                  placeholder="enter email"
                  {...register('email', {
                    required: true,
                    minLength: 3,
                    maxLength: 35,
                  })}
                />
              </div>
            </div>
            <div className="row">
              <div className={`${errors['password'] ? 'has-error' : ''}`}>
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  placeholder="enter password"
                  {...register('password', {
                    required: true,
                    minLength: 3,
                    maxLength: 35,
                  })}
                />
              </div>
            </div>

            {/* show submission errors if they exist */}
            {!authResponse.success ? (
              <div className="row error-message">
                {' '}
                <p>{authResponse.message}</p>{' '}
              </div>
            ) : (
              <></>
            )}

            <div className="form-bottom-button-wrapper">
              <LoadingButton
                initialLabel="Login"
                onSubmit={handleSubmit(onSubmit)}
                type="submit"
                loadingLabel="Loging In..."
              />

            </div>
          </form>

          <div className="row">
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        </CardCentered>
      </RightPanel>
    </div>
  ); // [end] return function
} //[end] login component

export default LoginComponent;
